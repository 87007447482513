// 1. Your custom variables and variable overwrites.

//$global-font-family: 'Roboto';

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.

// 4. Import UIkit.
@import "~uikit/src/scss/uikit-theme.scss";

.uk-notification {
  width: auto;
}

.uk-notification-message {
  padding: 0;
  @extend .uk-background-default;
}

.uk-alert-error {
  @extend .uk-alert-danger;
}

.uk-alert-info, .uk-alert-success {
  @extend .uk-alert-primary;
}