mwc-circular-progress-four-color {
  --mdc-circular-progress-bar-color-1: #2196f3;
  --mdc-circular-progress-bar-color-2: #e91e63;
  --mdc-circular-progress-bar-color-3: #ffc107;
  --mdc-circular-progress-bar-color-4: #03dac5;
}

mwc-drawer {
  --mdc-drawer-width: 300px;
}

mwc-list {
  a:hover {
    text-decoration: none;
  }
}

mwc-button[red] {
  --mdc-theme-primary: #e9437a;
  --mdc-theme-on-primary: white;
}

mwc-button[pink] {
  --mdc-theme-primary: #9b43af;
  --mdc-theme-on-primary: white;
}

mwc-button[green] {
  --mdc-theme-primary: #43af45;
  --mdc-theme-on-primary: white;
}

mwc-button[white] {
  --mdc-theme-primary: white;
  --mdc-theme-on-primary: rgba(0, 0, 0, 0.54);
}

mwc-button[white-blue] {
  --mdc-theme-primary: #DEC7FC;
  --mdc-theme-on-primary: #6200EE;
}

mwc-textfield {
  --mdc-text-field-disabled-ink-color: rgba(0, 0, 0, 0.6);
}

mwc-textfield[green] {
  --mdc-text-field-disabled-ink-color: #43af45;
}

mwc-textfield[red] {
  --mdc-text-field-disabled-ink-color: #e32e34;
}