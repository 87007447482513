$error-color: #f0506e;
$operator-card-header-default-color: #FAF9FF;
$operator-card-header-success-color: #43af50;
$operator-card-header-error-color: #f0506e;

.tm-anchor-block-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.tm-form-group {
  position: relative;
  @extend .uk-padding-small;
  @extend .uk-border-rounded;

  border: solid 1px #ECECEC;

  &>span:first-child {
    @extend .uk-padding-small;
    @extend .uk-padding-remove-vertical;
    @extend .uk-background-default;

    position: absolute;
    top: -12px;
  }
}

.tm-card-info {
  background-color: #faf9ff;
  border: solid 1px #e6e6e6;
}

@media print  {
  .tm-check {
    font-size: .6em;
  }
}

.turbo-progress-bar {
  visibility: hidden;
}

.tm-card-header {
  background-color: $operator-card-header-default-color;
  transition-property: background-color;
  transition-duration: .5s;

  &.tm-card-header-success {
    background-color: $operator-card-header-success-color;
  }

  &.tm-card-header-error {
    background-color: $operator-card-header-error-color;
  }
}

@media (min-width: 1400px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 960px) {
}