@import "~tabulator-tables/dist/css/tabulator_materialize.min";

.tabulator-row .tabulator-cell {
  overflow: visible !important;
}
.tabulator .tabulator-tableholder {
  overflow: visible !important;
}
.tabulator {
  overflow: visible !important;
  border: 1px solid #dee2e6;
}

.tabulator {
  mwc-menu {
    z-index: 999;
  }

  .tabulator-cell[tabulator-field="actions"] {
    padding: 0;
  }

  .tabulator-footer {
    background-color: white;
  }
}